<template>
  <div id="prize">
    <center><b>LEGO® UNIVERSE "BUILD A ROCKET” SWEEPSTAKES<br>
    OFFICIAL RULES<br>
    NO PURCHASE NECESSARY</b>
    </center>
    <br>
    <br>
    <b>1. OVERVIEW:</b>
     LEGO® Universe fans from the US, the UK, and Germany who submit their entries between January 14, 2011 00:00 GMT and February 11, 2011 23:59 GMT will be eligible to win one of the following prize packages:<br>
    <br>
    Top 1 Pick by Judges: Featured on the LEGO Universe Community Web Page, 1 LEGO Space Shuttle Adventure set (#10213), 1 LEGO Ninjago Garmadon's Dark Fortress set (#2505), 1 LEGO Ninjago Spinjitzu Dojo set (#2504), 1 LEGO Ninjago Nuckal's ATV set (#2518), 1 LEGO Ninjago Turbo Shredder set (#2263), 1 LEGO Ninjago Kai set (#2111), 1 LEGO Ninjago Cole set (#2112), 1 LEGO Ninjago Zane set (#2113), 1 LEGO Ninjago Chopov set (#2114), 1 LEGO Ninjago Bonezai set (#2115), 1 LEGO Ninjago Krazi set (#2116), 1 LEGO Ninjago Spinjitzu Starter Set (#2257), 1 LEGO Ninjago Ninja Ambush set (#2258), 1 LEGO Ninjago Skull Motorbike set (#2259), 1 LEGO Ninjago Ice Dragon Attack set (#2260), 1 LEGO Ninjago Ninja Training Outpost set (#2516), 1 LEGO Ninjago Skelton Bowling set (#2519), and 1 LEGO Ninjago Battler Arena set (#2520). The top winner's entry will also be eligible to become a functional rocket model in the LEGO Universe game in 2011.<br>
    <br>
    Next 2 Picks by Judges: Featured on the LEGO Universe Community Web Page, 1 LEGO Space Shuttle Adventure set (#10213), 1 LEGO Ninjago Garmadon's Dark Fortress set (#2505), 1 LEGO Ninjago Spinjitzu Dojo set (#2504), 1 LEGO Ninjago Nuckal's ATV set (#2518), 1 LEGO Ninjago Turbo Shredder set (#2263), 1 LEGO Ninjago Kai set (#2111), 1 LEGO Ninjago Cole set (#2112), 1 LEGO Ninjago Zane set (#2113), 1 LEGO Ninjago Chopov set (#2114), 1 LEGO Ninjago Bonezai set (#2115), 1 LEGO Ninjago Krazi set (#2116), 1 LEGO Ninjago Spinjitzu Starter Set (#2257), 1 LEGO Ninjago Ninja Ambush set (#2258), 1 LEGO Ninjago Skull Motorbike set (#2259), 1 LEGO Ninjago Ice Dragon Attack set (#2260), 1 LEGO Ninjago Ninja Training Outpost set (#2516), 1 LEGO Ninjago Skelton Bowling set (#2519), and 1 LEGO Ninjago Battler Arena set (#2520).<br>
    <br>
    <br>
    <b>2. HOW TO ENTER:</b>
     Build any LEGO Universe Rocket you can imagine. Rockets can be built either in LEGO Universe or out of entrant's LEGO bricks. Screenshots or photographs of the entry must be submitted through the LEGO Universe Creation Lab on http://www.legouniverse.com between January 14, 2011 00:00 GMT and February 11, 2011 23:59 GMT. No purchase is necessary to enter and win a prize. The time required to complete the necessary tasks will vary with the skill of the entrant. Limit: One (1) entry per person.<br>
    <br>
    <b>3. HOW TO WIN:</b>
     Once your Creation Lab entry has been approved, you will be automatically entered into the drawing; there is nothing more you need to do. Winners will be selected by a panel of Judges from the LEGO Universe Judge Team, and will be notified by the Team. Entries will be judged based on the quality of the entrants' overall build, taking into account how much the rocket conforms to the LEGO Universe rocket design format and the originality of the design. Decisions will be based on the subjective opinions of the Judges, and will be final. <br>
    <br>
    <b>4. ELIGIBILITY:</b>
     This promotion is open to residents of the 50 United States plus the District of Columbia, the United Kingdom, Germany, and Canada, except for the Province of Quebec. Employees of the LEGO Group, Net Devil Ltd, and Gazillion Entertainment, and members of their immediate families and households are not eligible to participate. <br>
    <br>
    All national, regional, and local laws and regulations apply. Void where prohibited by law.<br>
    <br>
    <b>5. AWARD PRIZES:</b>
     Three (3) winners will be picked from among all eligible entries on or about February 12th, 2011 by members of the LEGO Universe Judge Team, whose decisions will be final. Odds of winning will depend upon the number of eligible entries received, and the skill of the entrant. <br>
    <br>
    <b>6. PRIZES AND APPROXIMATE RETAIL VALUES:</b>
     There will be three (3) sets of prizes awarded. <br>
    <br>
    Top 1 Picks by Judges : Featured on the LEGO Universe Community Web Page, 1 LEGO Space Shuttle Adventure set (#10213), 1 LEGO Ninjago Garmadon's Dark Fortress set (#2505), 1 LEGO Ninjago Spinjitzu Dojo set (#2504), 1 LEGO Ninjago Nuckal's ATV set (#2518), 1 LEGO Ninjago Turbo Shredder set (#2263), 1 LEGO Ninjago Kai set (#2111), 1 LEGO Ninjago Cole set (#2112), 1 LEGO Ninjago Zane set (#2113), 1 LEGO Ninjago Chopov set (#2114), 1 LEGO Ninjago Bonezai set (#2115), 1 LEGO Ninjago Krazi set (#2116), 1 LEGO Ninjago Spinjitzu Starter Set (#2257), 1 LEGO Ninjago Ninja Ambush set (#2258), 1 LEGO Ninjago Skull Motorbike set (#2259), 1 LEGO Ninjago Ice Dragon Attack set (#2260), 1 LEGO Ninjago Ninja Training Outpost set (#2516), 1 LEGO Ninjago Skelton Bowling set (#2519), and 1 LEGO Ninjago Battler Arena set (#2520). The top winner's entry will also be eligible to become a functional rocket model in the LEGO Universe game in 2011 (Estimated value: $499 USD).<br>
    <br>
    Next 2 Picks by Judges : Featured on the LEGO Universe Community Web Page, 1 LEGO Space Shuttle Adventure set (#10213), 1 LEGO Ninjago Garmadon's Dark Fortress set (#2505), 1 LEGO Ninjago Spinjitzu Dojo set (#2504), 1 LEGO Ninjago Nuckal's ATV set (#2518), 1 LEGO Ninjago Turbo Shredder set (#2263), 1 LEGO Ninjago Kai set (#2111), 1 LEGO Ninjago Cole set (#2112), 1 LEGO Ninjago Zane set (#2113), 1 LEGO Ninjago Chopov set (#2114), 1 LEGO Ninjago Bonezai set (#2115), 1 LEGO Ninjago Krazi set (#2116), 1 LEGO Ninjago Spinjitzu Starter Set (#2257), 1 LEGO Ninjago Ninja Ambush set (#2258), 1 LEGO Ninjago Skull Motorbike set (#2259), 1 LEGO Ninjago Ice Dragon Attack set (#2260), 1 LEGO Ninjago Ninja Training Outpost set (#2516), 1 LEGO Ninjago Skelton Bowling set (#2519), and 1 LEGO Ninjago Battler Arena set (#2520). (Estimated value: $499 USD).<br>
    <br>
    No substitution of prizes will be allowed.<br>
    <br>
    <b>7. GENERAL RULES:</b>
     <br>
    <br>
    Winners will be notified by email to the winner's email address of record. <br>
    <br>
    No substitution of prize or transfer of prize to another person is permitted. Sponsor reserves the right to substitute a prize of equal or greater value in the unlikely event that the intended prize is not available, or in order to award a prize appropriate for the age of the winner. Sponsor reserves the right to modify the winning entry to make it technically suitable for the game. <br>
    <br>
    All decisions of the Judges are final.<br>
    <br>
    All income taxes and fees and/or duties, if applicable, are the sole responsibility of each winner. <br>
    <br>
    In no event will more than the stated number of prizes be awarded.<br>
    <br>
    If an entrant submits more than the stated number of entries permitted, or if the Sponsor suspects that an entrant attempted to obtain additional entries by using multiple email addresses, registrations, identities, or any other method, all entries submitted by the entrant will be declared null and void.<br>
    <br>
    By accepting a prize, winners (and their parent or legal guardian if winner is a minor) agree to hold Sponsor, its directors, officers, employees, and assigns harmless against any and all claims and liability arising out of use of the prize. Winners (and their parent or legal guardian if winner is a minor) assume all liability for any injury or damage caused, or claimed to be caused, by participation in this promotion or use or redemption of any prize. Acceptance of a prize constitutes permission for the Sponsor to use winner's name, likeness, and entry submission for purposes of advertising and trade, including posting the winning entries on the LEGO Universe Community webpage, without further compensation, and to use a winner's name for purposes of publishing a winners list, unless prohibited by law.<br>
    <br>
    Prizes will be mailed to each winner when available, but not later than 90 days from the prize award date. It is the responsibility of the winner to inform the Sponsor of the proper shipping address.<br>
    <br>
    Entrants (and their parent or legal guardian if entrant is a minor) agree to release, discharge and hold harmless Sponsor, its directors, officers, employees and assigns, harmless from and against any and all liability and damages. By participating in this promotion, entrants (and their parent or legal guardian if entrant is a minor) agree to be bound by the Official Rules and the judges’ decisions, which are final. In the event there is a discrepancy or inconsistency between disclosures or other statements contained in any promotion materials and the terms and conditions of the Official Rules, the Official Rules shall prevail, govern and control. Sponsor is not responsible for any typographical or other error in the printing of the offer, administration of the giveaway promotion, or in the announcement of the prizes. <br>
    <br>
    <b>Special rules for United States residents:</b>
     Winners who are residents of the United States (or a parent or legal guardian if a potential winner is deemed a minor in his/her state of residence) will be required to complete and return an Affidavit of Eligibility, Release of Liability, and a Prize Acceptance Form within 5 days of such forms being emailed. Noncompliance within this time period or return of any prize or prize notification as undeliverable will result in disqualification without further notice and an alternate winner will be selected. (End of US residents special rules).<br>
    <br>
    <b>Special rules for Canadian residents:</b>
     To be declared a winner, residents of Canada (or a parent or guardian if a potential winner is deemed a minor in his/her province or territory of residence) must correctly answer an arithmetical skill-testing question and execute a Declaration and Release Form to release Sponsor and its agencies and representatives from liability with respect to contests and prizes within 5 days of such form being emailed. Noncompliance within this time period or return of any prize or prize notification as undeliverable will result in disqualification without further notice and an alternate winner will be selected. (End of Canadian residents special rules).<br>
    <br>
    Not responsible for faulty, incorrect or mis-transcribed email transmissions, incorrect announcements of any kind, technical hardware or software failures of any kind including any injury or damage to any person's computer related to or resulting from participating in or experiencing any materials in connection with the promotion, lost or unavailable network connections, or failed, incomplete, garbled or delayed computer transmission that may limit a user's ability to participate in the promotion. Sponsor assumes no responsibility for undeliverable emails resulting from any form of active or passive email filtering by a user's internet service provider and/or email client or for insufficient space in user's email account to receive email. Sponsor reserves the right to cancel or modify the promotion if fraud, misconduct or technical failures destroy the integrity of the program; or if a computer virus, bug, or other technical problem corrupts the administration or security of the program as determined by Sponsor, in its sole discretion. In the event of termination of online entry, a notice will be posted online indicating how to enter via the mail. The drawing will be conducted from among all eligible online entries received prior to termination and all eligible mail-in entries received. Any damage made to the Web Site will be the responsibility of the authorized email account holder of the email address of record. Proof of submitting entries will not be deemed to be proof of receipt by Sponsor. Any entries which are suspected of being fraudulent (including those using robotic, automatic, programmed or similar methods of participation) will be disqualified, based on determinations made solely by Sponsor. Sponsor reserves the right to prohibit the participation of an individual if fraud or tampering is suspected or if the account holder fails to comply with any requirement of participation as stated herein or with any provision in these Official Rules.<br>
    <br>
    <b>Notice:</b>
     Any attempt by an individual to deliberately damage any web site or undermine the legitimate operation of this promotion is a violation of criminal and civil laws, and should such an attempt be made, sponsor reserves the right to seek damages from any such individual to the fullest extent permitted by law. <br>
    <br>
    <b>8. WINNERS:</b>
     For the names of the winners, send a separate, stamped, self-addressed (#10) envelope to: LEGO® Universe "January Rocket Build” Giveaway, LEGO Systems, Inc., 555 Taylor Road, Enfield, CT 06083, USA, to be received by February 28nd, 2011. Residents of Canada and the U.S. State of Vermont may omit return postage.<br>
    <br>
    <b>9. SPONSOR:</b>
     LEGO Systems, Inc., 555 Taylor Road, Enfield, CT 06083, USA.<br>
    <br>
    LEGO is a trademark of the LEGO Group of Companies. © 2011 The LEGO Group. All rights reserved.<p></p>
  </div>
</template>
